import { Fragment, useContext } from "react";
import classNames from "classnames";
import React from "react";
import { Popover, Transition } from "@headlessui/react";

import Link from "../ui/Link";

export default function Header({ setShowModal }) {
  const listItemClassName =
    "uppercase font-semibold text-aptblue mx-3 border-b-transparent border-b-2 hover:border-b-aptred transition-all duration-200 last:mr-0";

  const mobileListItemClassname =
    "uppercase py-3 font-semibold text-white mx-3 border-b-transparent border-b-2 transition-all duration-200";

  const buttonClassname =
    "flex items-center px-4 text-white transition-all duration-200 h-9 bg-aptblue rounded-3xl hover:bg-aptred";
  const mobileButtonClassname =
    "flex items-center px-4 transition-all duration-200 bg-white h-9 text-aptblue rounded-3xl hover:bg-aptred";

  const menuItems = [
    {
      text: "About",
      onClick: () => {
        setShowModal("about");
      },
    },
    {
      text: "Water Tank FAQs",
      onClick: () => {
        setShowModal("faq");
      },
    },
    {
      text: "Contact Us",
      button: true,
      onClick: () => {
        setShowModal("contact");
      },
    },
  ];
  return (
    <Popover
      role="navigation"
      className="flex items-center"
      style={{
        height: 80,
        backgroundColor: "rgb(255, 255, 255)",
      }}
    >
      <div className="flex items-center justify-end w-full px-4 mx-auto nav-container md:px-5">
        <div className="mr-auto">
          <Link to="/">
            <div className="flex items-center ">
              <img
                className="h-8 mr-3"
                src="https://www.americanpipeandtank.com/wp-content/uploads/2020/09/icon-woodtanks.svg"
                loading="lazy"
                alt="Wood Tanks"
              />
              <div className="mt-1 text-lg font-black font-sourceserifpro text-aptblue md:text-xl">
                NYC Water Tank Inspections
              </div>
            </div>
          </Link>
        </div>
        <ul role="list" className="items-center hidden md:flex">
          {menuItems.map(({ text, button, onClick }) => (
            <li
              className={classNames(listItemClassName, {
                "border-b-0": button,
              })}
              key={text}
            >
              <a
                className={classNames(
                  "hover:cursor-pointer",
                  button && buttonClassname
                )}
                onClick={onClick}
              >
                {text}
              </a>
            </li>
          ))}
        </ul>
        <Popover.Button className="w-8 menu-button md:hidden">
          <div
            data-animation-type="lottie"
            data-src="https://www.americanpipeandtank.com/wp-content/themes/apt/assets/documents/menu.json"
            data-loop="0"
            data-direction="1"
            data-autoplay="0"
            data-is-ix2-target="1"
            data-renderer="svg"
            data-default-duration="0.5"
            data-duration="0.5"
            className="menu-button__icon"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 14"
              width="16"
              height="14"
              preserveAspectRatio="xMidYMid meet"
              style={{
                width: " 100%",
                height: "100%",
                transform: "translate3d(0px, 0px, 0px)",
              }}
            >
              <defs>
                <clipPath id="__lottie_element_2">
                  <rect width="16" height="14" x="0" y="0"></rect>
                </clipPath>
              </defs>
              <g clipPath="url(#__lottie_element_2)">
                <g
                  transform="matrix(1,0,0,1,0,2)"
                  opacity="1"
                  style={{ display: "block" }}
                >
                  <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                    <path
                      strokeLinecap="butt"
                      strokeLinejoin="miter"
                      fillOpacity="0"
                      strokeMiterlimit="4"
                      stroke="rgb(194,0,35)"
                      strokeOpacity="1"
                      strokeWidth="2"
                      d=" M0,0 C0,0 16,0 16,0"
                    ></path>
                  </g>
                </g>
                <g
                  transform="matrix(1,0,0,1,0,7)"
                  opacity="1"
                  style={{ display: "block" }}
                >
                  <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                    <path
                      strokeLinecap="butt"
                      strokeLinejoin="miter"
                      fillOpacity="0"
                      strokeMiterlimit="4"
                      stroke="rgb(194,0,35)"
                      strokeOpacity="1"
                      strokeWidth="2"
                      d=" M0,0 C0,0 16,0 16,0"
                    ></path>
                  </g>
                </g>
                <g
                  transform="matrix(1,0,0,1,0,12)"
                  opacity="1"
                  style={{ display: "block" }}
                >
                  <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                    <path
                      strokeLinecap="butt"
                      strokeLinejoin="miter"
                      fillOpacity="0"
                      strokeMiterlimit="4"
                      stroke="rgb(194,0,35)"
                      strokeOpacity="1"
                      strokeWidth="2"
                      d=" M0,0 C0,0 16,0 16,0"
                    ></path>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel className="absolute inset-x-0 z-20 transition origin-top-right transform top-20 md:hidden">
            <div className="text-white divide-y-2 shadow-lg ring-1 ring-black ring-opacity-5 bg-aptblue divide-gray-50">
              <div className="px-5 pt-5 pb-6">
                <ul role="list" className="flex flex-col items-center">
                  {menuItems.map(({ text, button, onClick }) => (
                    <li key={text} className={mobileListItemClassname}>
                      <a
                        className={classNames(
                          "hover:cursor-pointer",
                          button && mobileButtonClassname
                        )}
                        onClick={onClick}
                      >
                        {text}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </div>
    </Popover>
  );
}
