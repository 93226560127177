import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";

import Modal, { SectionHeading, Paragraph, ExternalLink } from "./Modal";

export default function AboutModal({ show, onClose }) {
  return (
    <Modal title="About This Map" show={show} onClose={onClose}>
      <Paragraph>
        American Pipe & Tank created this map to help building owners and
        managers easily keep track of the required annual tank cleanings across
        their portfolio.
      </Paragraph>
      <SectionHeading>Inspection Requirements</SectionHeading>
      <Paragraph>
        In New York City, the Department of Health & Mental Hygiene (DOHMH)
        mandates that all water tanks used for drinking water must be inspected
        annually. All inspections must be filed with DOHMH by January 15th of
        the next calendar year and performed by a licensed master plumber,
        professional engineer, or architect design professional.
      </Paragraph>
      <Paragraph>
        <ExternalLink to="https://www1.nyc.gov/site/doh/business/permits-and-licenses/drinking-water-tank-inspection-reporting.page">
          Learn more about Water Tank Inspection Reporting (nyc.gov)
        </ExternalLink>
      </Paragraph>

      <SectionHeading>Data</SectionHeading>
      <Paragraph>
        The inspection data is published every week by DOHMH and automatically
        updated on this map.
      </Paragraph>
      <Paragraph>
        <span className="italic">
          Certain inspection results for buildings with different BINs on the
          same Block/Lot may not be confusing. This only applies when there are
          multiple buildings with tanks in a complex (e.g. hospitals,
          universities, World Trade Center, etc.)
        </span>
      </Paragraph>
    </Modal>
  );
}

AboutModal.propTypes = {
  modalProperties: PropTypes.object,
  hideModal: PropTypes.func,
};
