import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Outlet, useLocation } from "react-router-dom";

import Map from "./Map.js";
import Geocoder from "./Geocoder.js";

function MapWrapper({ onLoad }) {
  const { pathname } = useLocation();
  const isReportView = pathname.includes("report");
  return (
    <div className="h-full">
      <Map
        onLoad={(d) => {
          onLoad(d);
        }}
      />
      <div className="top-0 left-0 flex flex-col w-full h-auto min-h-0 z-120 md:absolute md:w-96 md:max-h-full">
        <div
          className={classNames(
            "geocoder z-20 md:static md:mt-5 w-full md:w-96 md:-top-2 md:top-5 md:left-0 border-b border-t md:border-b-0 md:border-t-0 border-gray-200",
            {
              hidden: isReportView,
            }
          )}
        >
          <div className=" md:mx-5">
            <Geocoder />
          </div>
        </div>
        <div className="flex flex-col py-4 m-0 overflow-hidden bg-white md:m-5 md:rounded-lg md:shadow-md">
          <div className="relative flex flex-col flex-grow h-full min-h-0">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

MapWrapper.propTypes = {
  onLoad: PropTypes.func,
};

export default MapWrapper;
