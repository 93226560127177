import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line
import mapboxgl from "!mapbox-gl";
import { useDeviceSelectors } from "react-device-detect";
import { useLocation } from "react-router-dom";

import dummyGeojson from "../../util/dummyGeojson";
import useWindowDimensions from "../../util/useWindowDimensions";

const MEDIUM_SCREEN_MAP_LEFT_PADDING = 300;

mapboxgl.accessToken =
  "pk.eyJ1Ijoic2lsdmVqMTQiLCJhIjoiY2wzOWdnMWhmMDloNTNpbjNhNGxreWgxayJ9.8ypjBL56KCoV7NVn3ZYwVQ";
const Map = ({ onLoad }) => {
  // this ref holds the map DOM node so that we can pass it into Mapbox GL
  const mapNode = useRef(null);

  const [selectors] = useDeviceSelectors(window.navigator.userAgent);
  const { isMobile } = selectors;

  const { width } = useWindowDimensions();
  const isSmallViewport = width < 768; // tailwind breakpoint for "md"

  // instantiate the map, add sources and layers, event listeners, tooltips
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapNode.current,
      style: "mapbox://styles/silvej14/cl3j740jr001g15l7t4w3zfqm",
      bounds: [
        [-74.049108, 40.690359],
        [-73.945768, 40.739087],
      ],
      minZoom: 11,
      maxZoom: 18,
    });

    map.setPadding({
      left: isSmallViewport ? 0 : MEDIUM_SCREEN_MAP_LEFT_PADDING,
    });

    if (isMobile) {
      map.scrollZoom.disable();
    }

    map.on("load", () => {
      // Add zoom and rotation controls to the map.
      map.addControl(
        new mapboxgl.NavigationControl({
          showCompass: false,
        })
      );

      // get the lowest label layer to place other layers behind it
      const layers = map.getStyle().layers;
      const labelLayerId = layers.find(
        (layer) => layer.type === "symbol" && layer.layout["text-field"]
      ).id;

      // map.addSource("nyc-3d-buildings", {
      //   type: "vector",
      //   tiles: ["http://127.0.0.1:5500/tiles/{z}/{x}/{y}.pbf"],
      //   minzoom: 15,
      //   maxzoom: 16,
      // });

      // we need a hidden buildings layer so that we can select the building under the current water tank inspection circle
      // we can't use the add-3d-buildings layer because it is 3d, and querying may select a building that is extruded and
      // appears above/in front of the building we really want.
      map.addLayer(
        {
          id: "nyc-buildings-hidden",
          source: "composite",
          "source-layer": "buildings",
          filter: [
            "all",
            ["!=", "type", "building:part"],
            ["==", "underground", "false"],
          ],
          type: "fill",
          minzoom: 15,
          paint: {
            "fill-opacity": 0,
          },
        },
        labelLayerId
      );

      map.addLayer(
        {
          id: "add-3d-buildings",
          source: "composite",
          "source-layer": "buildings",
          filter: ["==", "extrude", "true"],
          type: "fill-extrusion",
          minzoom: 15,
          paint: {
            "fill-extrusion-color": "#ccc",

            // Use an 'interpolate' expression to
            // add a smooth transition effect to
            // the buildings as the user zooms in.
            "fill-extrusion-height": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "height"],
            ],
            "fill-extrusion-base": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "min_height"],
            ],
            "fill-extrusion-opacity": 0.7,
          },
          layout: {
            // visibility: "none",
          },
        },
        labelLayerId
      );

      map.addSource("water-tanks", {
        type: "geojson",
        data: dummyGeojson,
      });

      map.addLayer({
        id: "water-tanks-circle",
        type: "circle",
        source: "water-tanks",
        paint: {
          "circle-opacity": 0.7,
          "circle-color": [
            "match",
            ["string", ["get", "inspectionStatus"]],
            "current",
            "#238823",
            "previous",
            "#ffbf00",
            "earlier",
            "#d2222d",
            "#ccc",
          ],
          "circle-radius": {
            stops: [
              [10, 0.5],
              [16, 5],
            ],
          },
        },
        layout: {
          visibility: "none",
        },
      });

      map.addSource("selected-feature", {
        type: "geojson",
        data: dummyGeojson,
      });

      map.addLayer(
        {
          id: "selected-feature-fill",
          type: "fill",
          source: "selected-feature",
          paint: {
            "fill-opacity": 0.2,
            "fill-color": [
              "match",
              ["string", ["get", "inspectionStatus"]],
              "current",
              "#238823",
              "previous",
              "#ffbf00",
              "earlier",
              "#d2222d",
              "#ccc",
            ],
          },
        },
        "nyc-buildings-hidden"
      );

      onLoad(map);
      window.map = map; // for easier debugging and querying via console
    });

    return () => {
      map.remove();
    };
  }, []);

  useEffect(() => {
    if (!window.map) return;
    window.map.setPadding({
      left: isSmallViewport ? 0 : MEDIUM_SCREEN_MAP_LEFT_PADDING,
    });
  }, [isSmallViewport]);

  return (
    <div className="relative w-full h-3/6 md:h-full">
      <div
        className="w-full h-full"
        ref={mapNode}
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
};

Map.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  center: PropTypes.arrayOf(PropTypes.number),
  zoom: PropTypes.number,
  style: PropTypes.string,
  minZoom: PropTypes.number,
  maxZoom: PropTypes.number,
  padding: PropTypes.number,
  sources: PropTypes.object,
  layers: PropTypes.arrayOf(PropTypes.object),
  bounds: PropTypes.arrayOf(PropTypes.number),
  location: PropTypes.object,
  areaOfInterest: PropTypes.shape({
    type: PropTypes.string,
    properties: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      bbox: PropTypes.arrayOf(PropTypes.number),
    }),
    geometry: PropTypes.object,
  }),
  drawnFeature: PropTypes.object,
  serviceRequests: PropTypes.shape({
    type: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.object),
  }),
  highlightedFeature: PropTypes.object,
  startDateMoment: PropTypes.object,
  allGeometries: PropTypes.shape({
    type: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.object),
  }),
  drawMode: PropTypes.bool,
  onDraw: PropTypes.func,
  onLoad: PropTypes.func,
  onMapClick: PropTypes.func,
};

export default Map;
