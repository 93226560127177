import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useSearchParams } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { Helmet } from "react-helmet";
import bbox from "@turf/bbox";

import { TankInspectionTable } from "../report/BinReport";
import CircleMarkerSvg from "../report/CircleMarkerSvg";

const BinsReportSidebar = ({ map, inspections, setShowModal }) => {
  const [searchParams] = useSearchParams();
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [bins, setBins] = useState([]);

  useEffect(() => {
    const rawBins = searchParams.get("bins")
    const binParams = rawBins ? rawBins.split(",") : []
    setBins(binParams);
  }, []);

  useEffect(() => {
    if (!(map && bins.length && inspections)) return;

    const filteredInspections = inspections.features.filter((d) =>
      bins.includes(d.properties.bin.toString())
    );

    if (filteredInspections) {
      const binsAsNumbers = bins.map((d) => parseInt(d));
      // filter the water-tanks-circle layer
      map.setFilter("water-tanks-circle", ["in", "bin", ...binsAsNumbers]);

      // get bounds for these BINs
      const bounds = bbox({
        type: "FeatureCollection",
        features: filteredInspections,
      });

      // fit bounds to these BINs
      map.fitBounds(bounds, {
        padding: 50,
      });

      setSelectedFeatures(filteredInspections);
    }
  }, [map, bins]);

  // show 3d buildings, hide water tank inspections
  useEffect(() => {
    if (!map) return;
    map.setLayoutProperty("add-3d-buildings", "visibility", "none");
    map.setLayoutProperty("water-tanks-circle", "visibility", "visible");
  }, [map]);

  return (
    <>
      <div className="px-4">
        <Helmet>
          <meta charSet="utf-8" />
          <title>BINs Water Tank Inspection Report</title>
          <meta
            name="description"
            content="A map of rooftop water tank inspections in New York City.  Users can quickly find the status of their water tank and contact American Pipe and Tank for more information"
          />
        </Helmet>
        <div className="flex items-center mb-1 text-lg font-black font-sourceserifpro text-aptblue">
          BINs Water Tank Inspection Report
        </div>
        <div>
          {selectedFeatures &&
            selectedFeatures.map((d) => {
              const { bin, house_num, street_name,inspectionStatus, mostRecentReportingYearInspections } = d.properties;
              return (
                  <div key={bin}className="py-2 border-t last:border-b">
                    <div className="flex">
                      <div className="flex items-center text-md font-semibold leading-5 flex-grow">
                        <div className=" relative mr-1.5">
                            <CircleMarkerSvg inspectionStatus={inspectionStatus} />
                        </div>
                        {house_num} {street_name}{" "}
                      </div>
                      <div className="text-xs font-semibold text-gray-500 mt-0.5 flex-shrink-0">
                        BIN: {bin}
                      </div>
                    </div>
                    <TankInspectionTable
                      inspections={mostRecentReportingYearInspections}
                    />
                  </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

BinsReportSidebar.propTypes = {
  map: PropTypes.object,
};

export default BinsReportSidebar;
