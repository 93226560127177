import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { Helmet } from "react-helmet";

import BinReport from "./BinReport";
import dummyGeojson from "../../util/dummyGeojson";

const APT_BLUE = "#002764";
const BUILDINGS_DEFAULT_COLOR = "#ccc";

// create a circle-like polygon given a center point and radius
// https://stackoverflow.com/questions/37599561/drawing-a-circle-with-the-radius-in-miles-meters-with-mapbox-gl-js/39006388#39006388
function createGeoJSONCircle(center, radiusInKm, points = 64) {
  const coords = {
    latitude: center[1],
    longitude: center[0],
  };

  const km = radiusInKm;

  const ret = [];
  const distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
  const distanceY = km / 110.574;

  let theta;
  let x;
  let y;
  for (let i = 0; i < points; i += 1) {
    theta = (i / points) * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    ret.push([coords.longitude + x, coords.latitude + y]);
  }
  ret.push(ret[0]);

  return {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [ret],
    },
  };
}

const circleFromFeature = (feature) => {
  const circleFeature = createGeoJSONCircle(feature.geometry.coordinates, 0.05);
  return {
    ...circleFeature,
    properties: {
      ...feature.properties,
    },
  };
};

const ReportSidebar = ({ map, inspections, setShowModal }) => {
  const { bin: binParam } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [feature, setFeature] = useState();

  useEffect(() => {
    if (!(map && feature)) return;

    map.flyTo({
      center: feature.geometry.coordinates,
      zoom: 16.5,
      pitch: 15,
    });

    // highlight the building parts that match this BIN
    map.setPaintProperty("add-3d-buildings", "fill-extrusion-color", [
      "match",
      ["get", "nycdoitt:bin"],
      binParam,
      APT_BLUE,
      BUILDINGS_DEFAULT_COLOR,
    ]);

    const {
      house_num,
      street_name,
      borough,
      inspectionStatus,
      mostRecentReportingYearInspections,
    } = feature.properties;
    const mostRecentInspectionDate =
      mostRecentReportingYearInspections[0]["INSPECTION_DATE"];

    // TODO: if there is no matching BIN in the 3d buildings layer, this is a backup method
    // for selecting the intersecting building parts... it may not work in some cases
    // leaving it here in case we need it
    //   // highlight the selected building
    //   map.once("moveend", () => {
    //     const [theBuildingFeature] = map.queryRenderedFeatures(
    //       map.project(feature.geometry.coordinates),
    //       {
    //         layers: ["nyc-buildings-hidden"],
    //       }
    //     );

    //     if (theBuildingFeature) {
    //       const bin = theBuildingFeature.properties["nycdoitt:bin"];

    //       // if there's no nycdoitt:bin property, match on @id instead
    //       map.setPaintProperty("add-3d-buildings", "fill-extrusion-color", [
    //         "match",
    //         ["get", "@id"],
    //         theBuildingFeature.properties["@id"],
    //         APT_BLUE,
    //         BUILDINGS_DEFAULT_COLOR,
    //       ]);
    //     }
    //   });
  }, [map, feature]);

  // show 3d buildings, hide water tank inspections
  useEffect(() => {
    if (!map) return;
    map.setLayoutProperty("add-3d-buildings", "visibility", "visible");
    map.setLayoutProperty("selected-feature-fill", "visibility", "visible");
    map.setLayoutProperty("water-tanks-circle", "visibility", "none");
  }, [map]);

  // choose the appropriate inspection from the data based on the URL
  useEffect(() => {
    if (binParam && inspections) {
      const binAsInt = parseInt(binParam);
      const selectedFeature = inspections.features.find(
        (d) => d.properties.bin === binAsInt
      );
      setFeature(selectedFeature);
    }
  }, [binParam, inspections]);

  useEffect(() => {
    if (!(map && feature)) return;
    const circle = circleFromFeature(feature);
    map.getSource("selected-feature").setData(circle);
  }, [map, feature]);

  // generate a title for use by react-helmet
  let title = "Water Tank Inspection Report";
  if (feature) {
    title += ` for ${feature.properties.house_num} ${feature.properties.street_name}`;
  }

  return (
    <>
      <div className="px-4">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{title}</title>
          <meta
            name="description"
            content="A map of rooftop water tank inspections in New York City.  Users can quickly find the status of their water tank and contact American Pipe and Tank for more information"
          />
        </Helmet>
        <div className="flex items-center mb-1 text-lg font-black font-sourceserifpro text-aptblue">
          Water Tank Inspection Report
        </div>

        {feature && <BinReport feature={feature} setShowModal={setShowModal} />}
        <Link to="/">
          <div className="flex items-center mt-3 text-aptred group">
            <ArrowLeftIcon className="relative h-4 transition-all duration-300 group-hover:-translate-x-1" />
            <div className="ml-2 text-sm font-semibold">
              Back to Citywide View
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

ReportSidebar.propTypes = {
  map: PropTypes.object,
};

export default ReportSidebar;
