import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";

import Modal, { SectionHeading, Paragraph, ExternalLink } from "./Modal";

export default function FAQModal({ show, onClose }) {
  return (
    <Modal title="Frequently Asked Questions" show={show} onClose={onClose}>
      <SectionHeading>
        What is the Department of Health & Mental Hygiene (DOHMH) code regarding
        Drinking Water Tanks (DWTs)?
      </SectionHeading>
      <Paragraph>
        The code mandates that any water tank that supplies drinking water to a
        building must be cleaning, disinfected, water sampled, and filed with
        DOHMH once per calendar year. Click here to learn more about the code.
      </Paragraph>
      <Paragraph>
        <ExternalLink to="https://www1.nyc.gov/assets/doh/downloads/pdf/environmental/watertank-insp-faq.pdf">
          Learn more about Water Tank Inspections (nyc.gov)
        </ExternalLink>
      </Paragraph>
      <SectionHeading>
        Can I get fined for not cleaning my water tank every year?
      </SectionHeading>
      <Paragraph>
        Yes, fines start at $250 for failing to submit a previous year’s
        inspection report with DOHMH by January 15th. Click here to view the
        list of all penalties related to DWTs.
      </Paragraph>
      <Paragraph>
        <ExternalLink to="https://www1.nyc.gov/assets/doh/downloads/pdf/about/healthcode/health-code-chapter31.pdf">
          NYC Water Tank Inspection Penalties (nyc.gov)
        </ExternalLink>
      </Paragraph>

      <SectionHeading>How accurate is the data on this map?</SectionHeading>
      <Paragraph>
        Very accurate! The map uses data that is published by the Department of
        Health and Mental Hygiene and updated weekly. We pull the data shortly
        after it is released and keep the map up to date.
      </Paragraph>
    </Modal>
  );
}

FAQModal.propTypes = {
  modalProperties: PropTypes.object,
  hideModal: PropTypes.func,
};
