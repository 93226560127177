import React, { useState, useEffect, createContext } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import mapboxgl from "mapbox-gl";
import { renderToString } from "react-dom/server";
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';

import MapWrapper from "./features/map/MapWrapper";
import Header from "./layout/Header";
import MainSidebar from "./features/main/MainSidebar";
import ReportSidebar from "./features/report/ReportSidebar";
import BinsReportSidebar from "./features/binsReport/BinsReportSidebar";
import useFetch from "./util/useFetch";
import { slugFromName } from "./util/slugFromName";
import AboutModal from "./ui/modal/AboutModal";
import FAQModal from "./ui/modal/FAQModal";
import ContactModal from "./ui/modal/ContactModal";
import NotifyModal from "./ui/modal/NotifyModal";
export const InspectionsContext = createContext();
export const MapContext = createContext();

const TRACKING_ID = "UA-181614832-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);


function App() {
  const [mapInstance, setMapInstance] = useState();
  const [inspections, setInspections] = useState();
  const [zoom, setZoom] = useState();
  const [showModal, setShowModal] = useState("");

  const { data, loading, error, trigger } = useFetch({
    url: "/data/inspections.geojson",
  });

  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    if (data) {
      setInspections(data);
    }
  }, [data]);

  useEffect(() => {
    trigger();
  }, []);

  const navigate = useNavigate();
  // on mount, add this component's sources and layers to the map
  // only if they haven't been added before
  useEffect(() => {
    const map = mapInstance;

    if (!map || !inspections) return;

    map.getSource("water-tanks").setData(inspections);

    var popup = new mapboxgl.Popup({
      anchor: "bottom-left",
      closeButton: false,
      closeOnClick: false,
      offset: [10, -5],
      className: "popup-as-tooltip",
    });

    function identifyFeatures(location, layer, fields) {
      var [identifiedFeature] = map.queryRenderedFeatures(location.point, {
        layers: [layer],
      });
      popup.remove();

      if (identifiedFeature) {
        map.getCanvas().style.cursor = "pointer";
        const { properties } = identifiedFeature;
        const { house_num, street_name } = properties;
        const tooltipHtml = (
          <div className="px-2 py-1">
            {house_num} {street_name}
          </div>
        );
        popup
          .setLngLat(location.lngLat)
          .setHTML(renderToString(tooltipHtml))
          .addTo(map);
      } else {
        map.getCanvas().style.cursor = "";
      }
    }

    map.on("mousemove", function (e) {
      identifyFeatures(e, "water-tanks-circle");
    });

    map.on("click", "water-tanks-circle", function (e) {
      const [feature] = e.features;
      if (feature) {
        const { bin, house_num, street_name, borough, mostRecentReportingYearInspections } = feature.properties;
        const addressSlug = slugFromName(
          `${house_num} ${street_name} ${borough}`
        );
        setZoom(map.getZoom());
        let route = `report/${bin}/${addressSlug}`

        const mostRecentInspectionDate = JSON.parse(mostRecentReportingYearInspections)[0]["INSPECTION_DATE"];

        if (feature.properties.inspectionStatus === 'previous') {
          route = `${route}?address=${house_num} ${street_name}, ${borough}&last_inspection_date=${mostRecentInspectionDate}`
        }
    
        navigate(route);
      }
    });

    return () => {
      // map.getSource('all-geometries').setData(dummyGeojson)
    };
  }, [mapInstance, inspections]);

  const handleCloseModal = () => {
    setShowModal("");
  };

  return (
    <InspectionsContext.Provider value={inspections}>
      <MapContext.Provider value={mapInstance}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>NYC Water Tank Inspections Map | American Pipe and Tank</title>
          <meta
            name="description"
            content="A map of rooftop water tank inspections in New York City.  Users can quickly find the status of their water tank and contact American Pipe and Tank for more information"
          />
        </Helmet>
        <div className="flex flex-col App">
          <Header setShowModal={setShowModal} />
          <div className="relative flex-grow min-h-0">
            <Routes>
              <Route
                element={
                  <MapWrapper
                    onLoad={(map) => {
                      setMapInstance(map);
                    }}
                  />
                }
              >
                <Route
                  index
                  element={
                    <MainSidebar
                      map={mapInstance}
                      inspections={inspections}
                      zoom={zoom}
                    />
                  }
                />
                <Route
                  path="/report/:bin/:addressSlug"
                  element={
                    <ReportSidebar
                      map={mapInstance}
                      inspections={inspections}
                      setShowModal={setShowModal}
                    />
                  }
                />
                <Route
                  path="/binsreport"
                  element={
                    <BinsReportSidebar
                      map={mapInstance}
                      inspections={inspections}

                    />
                  }
                />
              </Route>
            </Routes>
          </div>
        </div>

        <AboutModal show={showModal === "about"} onClose={handleCloseModal} />

        <FAQModal show={showModal === "faq"} onClose={handleCloseModal} />

        <ContactModal
          show={showModal === "contact"}
          onClose={handleCloseModal}
        />

        <NotifyModal show={showModal === "notify"} onClose={handleCloseModal} />
      </MapContext.Provider>
    </InspectionsContext.Provider>
  );
}

export default App;
