import React from 'react'
import App from './App.js'

function AppContainer () {
  return (
        <App />
  )
}

export default AppContainer
