import CircleMarkerSvg from "./CircleMarkerSvg";

const GreenAlert = () => {
  return (
    <div
      className="p-3 mb-2 text-sm text-green-700 bg-green-100 border-l-4 border-green-700"
      role="alert"
    >
      <p className="font-bold">Your Tank Inspection is Current</p>
      <p>
        Your annual tank cleaning has already been filed with DOHMH this year.
      </p>
    </div>
  );
};

const YellowAlert = ({ setShowModal }) => {
  return (
    <div
      className="p-3 mb-2 text-sm text-yellow-700 bg-yellow-100 border-l-4 border-yellow-700"
      role="alert"
    >
      <p className="font-bold">
        Your tank cleaning has not been filed this year
      </p>
      <p>
        We can send you a free reminder email 45 days before the due date.{" "}
        <a>
          <button
            className="ml-2 h-5 px-1.5 font-semibold text-xs text-yellow-700 transition-colors duration-150 border border-yellow-500 rounded focus:shadow-outline hover:bg-yellow-700 hover:text-yellow-100"
            onClick={() => {
              setShowModal("notify");
            }}
          >
            Send me a Reminder
          </button>
        </a>
      </p>
    </div>
  );
};

const RedAlert = ({ setShowModal }) => {
  return (
    <div
      className="p-3 mb-2 text-sm text-red-700 bg-red-100 border-l-4 border-red-700"
      role="alert"
    >
      <p className="font-bold">Your tank cleaning is past due!</p>
      <p>
        Contact us to bring your building's water tanks up to code.
        <a
          onClick={() => {
            setShowModal("contact");
          }}
        >
          <button className="ml-2 h-5 px-1.5 font-semibold text-xs text-red-700 transition-colors duration-150 border border-red-500 rounded focus:shadow-outline hover:bg-red-700 hover:text-red-100">
            Contact American Pipe & Tank
          </button>
        </a>
      </p>
    </div>
  );
};

export const TankInspectionTable = ({ inspections }) => (
    <table className="w-full table-auto">
    <thead>
      <tr className="font-light text-gray-500">
        <th className="text-xs font-light text-left text-gray-500 ">
          Tank Number
        </th>
        <th className="text-xs font-light text-left text-gray-500">
          Last Inspection Date
        </th>
      </tr>
    </thead>
    <tbody>
      {inspections.map(
        ({ TANK_NUM, INSPECTION_DATE }) => {
          return (
            <tr className="text-sm" key={`${TANK_NUM}${INSPECTION_DATE}`}>
              <td>{TANK_NUM}</td>
              <td>{INSPECTION_DATE}</td>
            </tr>
          );
        }
      )}
    </tbody>
  </table>
  )

export default function BinReport({ feature, setShowModal }) {
  const {
    bin,
    house_num,
    street_name,
    borough,
    tankCount,
    inspectionStatus,
    mostRecentReportingYearInspections,
  } = feature?.properties || {};
  return (
    <>
      <div className="flex items-start pb-2 mt-s">
        <img
          className="h-10 mr-3 mt-1.5"
          src="https://www.americanpipeandtank.com/wp-content/uploads/2020/09/icon-woodtanks.svg"
          loading="lazy"
          alt="Wood Tanks"
        />

        <div className="flex-grow">
          <div className="text-xs font-semibold text-aptred ">{borough}</div>
          <div className="flex items-center text-xl font-semibold leading-5">
            <div className=" relative mr-1.5">
              <CircleMarkerSvg inspectionStatus={inspectionStatus} />
            </div>
            {house_num} {street_name}{" "}
          </div>
          <div className="text-xs font-semibold text-gray-500 mt-0.5">
            BIN: {bin}
          </div>
        </div>
        <div className="flex flex-col items-center flex-grow-0 text-gray-600">
          <div className="text-4xl font-black">{tankCount}</div>
          <div className="-mt-1 text-sm font-semibold">
            TANK{tankCount > 1 && "S"}
          </div>
        </div>
      </div>
      {inspectionStatus == "current" && <GreenAlert />}
      {inspectionStatus == "previous" && (
        <YellowAlert setShowModal={setShowModal} />
      )}
      {inspectionStatus == "earlier" && (
        <RedAlert setShowModal={setShowModal} />
      )}

      <div className="py-2 border-t border-b">
        <TankInspectionTable inspections={mostRecentReportingYearInspections}/>
      </div>
    </>
  );
}
