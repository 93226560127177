import React, { useEffect, useState, useContext, useRef } from "react";
import classNames from "classnames";
import { useNavigate, useLocation } from "react-router-dom";
import mapboxgl from "mapbox-gl";

import { SearchIcon, XIcon } from "@heroicons/react/outline";

import useDebounce from "../../util/useDebounce";
import { InspectionsContext, MapContext } from "../../App";
import { slugFromName } from "../../util/slugFromName";

export default function Geocoder() {
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState();
  const [selectedItem, setSelectedItem] = useState(0);
  const [loading, setLoading] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 500);

  const marker = useRef(
    new mapboxgl.Marker({
      color: "#002764",
    })
  );

  const popup = useRef(
    new mapboxgl.Popup({
      closeOnClick: false,
      offset: [0, -40],
      closeButton: false,
    })
  );

  const inspections = useContext(InspectionsContext);
  const map = useContext(MapContext);

  const { pathname } = useLocation();

  const handleClear = () => {
    marker.current.remove();
    popup.current.remove();
    setSearchText("");
    setSearchResults(null);
    setSelectedItem(0);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (!searchText) {
      setSearchResults();
      return;
    }

    const getSearchResults = async () => {
      setLoading(true);
      const results = await fetch(
        `https://geosearch.planninglabs.nyc/v2/autocomplete?text=${debouncedSearchText}&size=5`
      ).then((d) => {
        setLoading(false);
        return d.json();
      });

      // lookup bins in the tank inspections data
      const resultsWithTanks = results.features.slice(0, 5).map((d) => {
        const binAsInt = parseInt(d.properties.addendum.pad.bin);
        const match = inspections.features.find((feature) => {
          return feature.properties.bin === binAsInt;
        });

        let tankCount = 0;

        if (match) {
          tankCount = match.properties.tankCount;
        }

        return {
          ...d,
          properties: {
            ...d.properties,
            tankCount,
          },
        };
      });

      setSelectedItem(0);
      setSearchResults(resultsWithTanks);
    };

    getSearchResults();
  }, [debouncedSearchText]);

  useEffect(() => {
    if (pathname.includes("report")) {
      handleClear();
    }
  }, [pathname]);

  const handleKeyDown = (e) => {
    if (["Enter", "Tab"].includes(e.code) && searchResults) {
      navigateToResult(selectedItem);
    }

    if (e.code === "ArrowUp") {
      if (selectedItem > 0) {
        setSelectedItem(selectedItem - 1);
      }
    }

    if (e.code === "ArrowDown") {
      if (selectedItem < searchResults.length - 1) {
        setSelectedItem(selectedItem + 1);
      }
    }
  };

  const navigateToResult = (selectedItemIndex) => {
    const result = searchResults[selectedItemIndex];

    const { addendum, tankCount, housenumber, street, borough } =
      result.properties;

    const { bin } = addendum.pad
    // TODO: check local index to see if this bin has a tank
    // if not, fly map but don't navigate
    if (tankCount) {
      const addressSlug = slugFromName(`${housenumber} ${street} ${borough}`);
      navigate(`report/${bin}/${addressSlug}`);
    } else {
      map?.flyTo({
        center: result.geometry.coordinates,
      });

      setSearchText(result.properties.label);
      popup.current
        .setLngLat(result.geometry.coordinates)
        .setHTML(
          `<div class="px-2 py-1 text-center">
            <div class='text-sm font-medium'>
              ${housenumber} ${street} ${borough}
            <div>
            <div class='text-xs text-gray-500'>No Water Tanks at this Address</div>
          </div>`
        )
        .addTo(map);

      marker.current.setLngLat(result.geometry.coordinates).addTo(map);
    }
  };

  return (
    <div className="relative w-full h-full text-gray-600 ">
      <SearchIcon className="absolute h-4 top-2.5 left-2 " />
      <input
        className="w-full md:shadow-md h-9 md:rounded px-9 focus:outline-1 outline-aptblue peer"
        placeholder="Search for a Building"
        value={searchText}
        onKeyDown={handleKeyDown}
        onChange={(e) => {
          setSearchText(e.target.value || "");
        }}
      />
      {searchResults && (
        <div
          onClick={handleClear}
          className="absolute bg-white hover:cursor-pointer hover:bg-gray-100 top-2 right-2"
        >
          <XIcon className="h-5 " />
        </div>
      )}
      {loading && (
        <div className="absolute top-2 right-2">
          <div
            className="inline-block w-5 h-5 border-2 rounded-full spinner-border animate-spin"
            role="status"
          ></div>
        </div>
      )}

      <div className="absolute invisible w-full mt-1 rounded shadow-md suggestions peer-focus:visible">
        {searchText && searchResults?.length === 0 && (
          <div className="py-1 text-center bg-white rounded">
            No Results Found
          </div>
        )}
        {searchResults?.map((result, i) => {
          const { housenumber, street, borough, tankCount } = result.properties;
          return (
            <div
              key={i}
              className={classNames(
                "px-2 py-1 hover:bg-gray-100 hover:cursor-pointer first:rounded-t last:rounded-b leading-3 flex",
                {
                  "bg-gray-100": i === selectedItem,
                  "bg-white": i !== selectedItem,
                }
              )}
              onMouseDown={() => {
                navigateToResult(i);
              }}
            >
              <div className="flex-grow">
                {" "}
                <div className="text-sm font-semibold">
                  {housenumber} {street}
                </div>
                <div className="text-xs -mt-0.5">{borough}</div>
              </div>

              <div
                className="flex flex-col items-center justify-center font-semibold text-center text-gray-500"
                style={{ fontSize: 9 }}
              >
                <div
                  className={classNames(
                    "inline-flex items-center justify-center px-1 text-xs rounded-full",
                    {
                      "text-white bg-aptblue": tankCount,
                      "bg-gray-200": !tankCount,
                    }
                  )}
                >
                  {tankCount}
                </div>
                TANK{tankCount !== 1 && "S"}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
