import React from "react";
import PropTypes from "prop-types";
import HubspotForm from "react-hubspot-form";

import Spinner from "../Spinner";
import Modal, { Paragraph } from "./Modal";

export default function ContactModal({ show, onClose }) {
  return (
    <Modal
      title="Get an Inspection Reminder"
      show={show}
      showCloseButton={false}
      onClose={onClose}
    >
      <Paragraph>
      Complete the form below and our team will contact you with a reminder 45 days prior to the due date of your next tank cleaning.
      </Paragraph>
      <div className="mt-3 mb-6">
        <HubspotForm
          portalId="7379468"
          formId="0647ec78-a89c-4bf0-aacd-fdd1a20cf409"
          onSubmit={() => {
            onClose();
          }}
          loading={<Spinner />}
        />
      </div>
    </Modal>
  );
}

ContactModal.propTypes = {
  modalProperties: PropTypes.object,
  hideModal: PropTypes.func,
};
