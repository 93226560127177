import React from "react";
import PropTypes from "prop-types";
import HubspotForm from "react-hubspot-form";

import Modal, { Paragraph } from "./Modal";
import Spinner from "../Spinner";

export default function ContactModal({ show, onClose }) {
  return (
    <Modal
      title="Contact American Pipe & Tank"
      show={show}
      showCloseButton={false}
      onClose={onClose}
    >
      <Paragraph>
        Contact us to help bring your building's water tank(s) up to code.
      </Paragraph>
      <div className="mt-3 mb-6">
        <HubspotForm
          portalId="7379468"
          formId="f6a3df82-ca2a-47b8-87cd-906bec160afa"
          onSubmit={() => {
            onClose();
          }}
          loading={<Spinner />}
        />
      </div>
    </Modal>
  );
}

ContactModal.propTypes = {
  modalProperties: PropTypes.object,
  hideModal: PropTypes.func,
};
