import React from 'react'
import PropTypes from 'prop-types'

// generate an SVG circle that resembles the circlemarkers used on the map
const CircleMarkerSvg = ({ inspectionStatus, small }) => {

  let fillColor = '#d2222d'

  let size = {
    width: 14,
    offset: 7,
    radius: 5
  }

  if (small) { 
    size = {
      width: 12,
      offset: 6,
      radius: 4
    }
  }

  if (inspectionStatus === 'current') {
    fillColor = '#238823'
  }

  if (inspectionStatus === 'previous') {
    fillColor =  '#ffbf00'
  }

  return (
    <svg height={size.width} width={size.width}>
      <circle cx={size.offset} cy={size.offset} r={size.radius} fill={fillColor} />
    </svg>
  )
}

CircleMarkerSvg.propTypes = {
  inspectionStatus: PropTypes.string,
  small: PropTypes.bool
}

export default CircleMarkerSvg
